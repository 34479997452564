export enum FormFiller {
    CUSTOMER = 'CUSTOMER',
    CONTRACTOR = 'CONTRACTOR'
}

export enum W9CRContractorStatus {
    NEW = 'NEW',
    FILLING_BY_CUSTOMER = 'FILLING_BY_CUSTOMER',
    FILLING_BY_CONTRACTOR = 'FILLING_BY_CONTRACTOR',
    CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
    CONTRACTOR_HAS_NOT_BEEN_REACHED = 'CONTRACTOR_HAS_NOT_BEEN_REACHED',
    DOCUMENTS_PROVIDED = 'DOCUMENTS_PROVIDED',
    PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL',
    COMPLETED = 'COMPLETED',
}