

















































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import PortalInput from "@/components/common/PortalInput.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import W9CRContractorDTO from "@/dto/request/w9cr/W9CRContractorDTO";
import {namespace} from "vuex-class";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {InputType} from "@/components/common/InputType";
import AutoComplete from "@/components/common/AutoComplete.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import {FormFiller, W9CRContractorStatus} from "@/constants/request/W9CRConstants";
import W9CRService from "@/services/request/W9CRService";
import {ifValid} from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component({
  computed: {
    W9CRContractorStatus() {
      return W9CRContractorStatus
    },
    FormFiller() {
      return FormFiller
    },
    AutoCompleteDTO() {
      return AutoCompleteDTO
    },
    InputType() {
      return InputType
    }
  },
  components: {SingleFileHolder, PortalInput, AutoComplete, PhoneInput}
})
export default class W9CRPersonData extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  private contractor!: W9CRContractorDTO;

  @Prop()
  private filler!: FormFiller;

  get isSsnFilled(): boolean {
    return this.contractor.individualSsn?.length == 11;
  }

  get isItinFilled(): boolean {
    return this.contractor.individualSsn?.length == 11;
  }

  // get isFilled() {
  //   return this.contractor.email
  //       && (!!this.contractor.stateIdFrontImage || (!!this.contractor.individualFirstName && !!this.contractor.individualLastName && this.isAddressFilled))
  //       && (this.isSsnFilled || this.isItinFilled || this.contractor.ssnImage);
  // }

  get isFilled() {
    return this.contractor.email
        && !!this.contractor.idCard
        && !!this.contractor.ssnImage
        && !!this.contractor.proofOfAddress
        && !!this.contractor.voidCheck;
  }

  get isAddressFilled(): boolean {
    return !!this.contractor.address.address1 && !!this.contractor.address.city && !!this.contractor.address.state && !!this.contractor.address.zipCode;
  }

  complete() {
    ifValid(this, () => {
      this.startLoading();
      this.contractor.status = W9CRContractorStatus.DOCUMENTS_PROVIDED;
      const saveFun = this.filler == FormFiller.CUSTOMER ? W9CRService.saveContractorByCustomer : W9CRService.saveContractorByContractor
      saveFun(this.$route.params.id, this.contractor).then(
          ok => {
            this.stopLoading();
            console.log("OK")
          },
          err => {
            console.log(JSON.stringify(err))
            this.contractor.status = this.filler == FormFiller.CUSTOMER ? W9CRContractorStatus.FILLING_BY_CUSTOMER : W9CRContractorStatus.FILLING_BY_CONTRACTOR;
            this.stopLoading();
          }
      )
    })
  }

}
