import axios from 'axios';
import {REQUESTS_W9CR_CONTRACTOR_ENDPOINT, REQUESTS_W9CR_ENDPOINT, REQUESTS_W9CR_PUBLIC_CONTRACTOR_ENDPOINT, REQUESTS_W9CR_PUBLIC_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import CreateW9CRPayload from "@/dto/request/w9cr/CreateW9CRPayload";
import W9CR_DTO from "@/dto/request/w9cr/W9CR_DTO";
import W9CRContractorDTO from "@/dto/request/w9cr/W9CRContractorDTO";

class W9CRService {

    create(payload: CreateW9CRPayload){
        return axios.post(REQUESTS_W9CR_ENDPOINT, payload, {headers: authHeader()});
    }

    getById(id: number){
        return axios.get<W9CR_DTO>(`${REQUESTS_W9CR_ENDPOINT}/${id}`, {headers: authHeader()})
    }

    getPublic(id: string){
        return axios.get<W9CR_DTO>(`${REQUESTS_W9CR_PUBLIC_ENDPOINT}/${id}`)
    }

    getPublicContractor(id: string){
        return axios.get<W9CRContractorDTO>(`${REQUESTS_W9CR_PUBLIC_CONTRACTOR_ENDPOINT}/${id}`)
    }

    saveContractorByCustomer(publicId: string, payload: W9CRContractorDTO){
        return axios.put(`${REQUESTS_W9CR_PUBLIC_ENDPOINT}/${publicId}`, payload)
    }

    saveContractorByContractor(publicId: string, payload: W9CRContractorDTO){
        return axios.put(`${REQUESTS_W9CR_PUBLIC_CONTRACTOR_ENDPOINT}/${publicId}`, payload)
    }

    saveContractorByEmployee(payload: W9CRContractorDTO){
        return axios.put(`${REQUESTS_W9CR_CONTRACTOR_ENDPOINT}`, payload, {headers: authHeader()})
    }

}

export default new W9CRService();